import React, { useEffect, useState } from "react";
import useResourcesActions from "../../hooks/useResourcesActions";
import { isEmpty } from "lodash";
import { NotFound, Resource, useDataProvider, useNotify } from "react-admin";

import { Card } from "@material-ui/core";
import CustomLoading from "../../components/CustomLoading";

import GrammarDataListHeader from "./GrammarDataListHeader";
import GrammarDataList from "../../dashboard/grammar-data/GrammarDataList";
import GrammarDataEdit from "../../dashboard/grammar-data/GrammarDataEdit";
import { useParams } from "react-router-dom";

const GrammarListRoute = () => {
  const { id: initialSlug = "definite-articles" } = useParams();
  const [slug, setSlug] = useState(initialSlug);

  useEffect(() => {
    setSlug(initialSlug);
  }, [initialSlug]);

  const [choices, setChoices] = React.useState<IVocabDataItem[]>([]);
  const provider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    provider
      .getList("grammars", {
        pagination: {
          perPage: 150,
          page: 1,
        },
        filter: {},
        sort: {
          field: "primaryTagId",
          order: "ASC",
        },
      })
      .then((res) => {
        if (res.total) {
          setChoices(res?.data || []);
          if (!slug) {
            const newSlug = res?.data?.[0]?.slug || "definite-articles";
            setSlug(newSlug);
          }
        }
      })
      .catch((e) => {
        notify(e?.message);
      });
  }, [provider, slug, notify]);

  const permissionActions = useResourcesActions({
    resource: "grammar-data",
  });

  if (permissionActions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissionActions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Card>
      <GrammarDataListHeader
        choices={choices}
        slug={slug}
        onChange={(value: string) => {
          setSlug(value);
        }}
      />
      <Resource
        name={"data/grammars/" + slug}
        list={GrammarDataList}
        edit={GrammarDataEdit}
      />
    </Card>
  );
};

export default GrammarListRoute;
