import React from "react";

import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";

import CustomLoading from "../../components/CustomLoading";
import GrammarList from "../../dashboard/grammars";
import GrammarCreate from "../../dashboard/grammars/GrammarCreate";
import GrammarEdit from "../../dashboard/grammars/GrammarEdit";
import useResourcesActions from "../../hooks/useResourcesActions";

const GrammarResource = () => {
  const permissions = useResourcesActions({
    resource: "grammars",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="grammars"
      list={GrammarList}
      edit={GrammarEdit}
      create={GrammarCreate}
    />
  );
};

export default GrammarResource;
