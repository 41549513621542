import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  ResourceProps,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import CustomCreateButton from "../../components/CustomCreateButton";

const CustomToolBar = ({
  overrideData = {},
}: {
  overrideData?: {
    [property: string]: any;
  };
}) => {
  return (
    <Toolbar>
      <Toolbar>
        <CustomCreateButton
          resource="grammars"
          identifier="slug"
          overrideData={overrideData}
        />
      </Toolbar>
    </Toolbar>
  );
};

const GrammarCreate = (props: ResourceProps["create"]) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const provider = useDataProvider();
  const notify = useNotify();
  const [primaryTag, setPrimaryTag] = useState(null);
  const [secondaryTags, setSecondaryTags] = useState<string[]>([]);

  useEffect(() => {
    provider
      .getList("grammar-tags", {
        pagination: {
          perPage: 100,
          page: 1,
        },
        filter: {},
        sort: {
          field: "tag",
          order: "ASC",
        },
      })
      .then((res) => {
        if (res.total) {
          setTags(res?.data || []);
        }
      })
      .catch((e) => {
        notify(e?.message);
      });
  }, [provider, notify]);

  return (
    <Create {...props}>
      <SimpleForm
        id="slug"
        toolbar={
          <CustomToolBar
            overrideData={{
              primaryTag: tags.find((t) => t.tag === primaryTag),
              secondaryTags: tags.filter((t: ITag) =>
                (secondaryTags || []).includes(t?.tag)
              ),
            }}
          />
        }
      >
        <TextInput source="slug" label="ID" />
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceInput source="primaryTagId" reference="grammar-tags">
              <SelectInput
                // disabled={!permissions?.edit?.tag}
                emptyText="None"
                source="tag"
                optionValue="tag"
                optionText="tag"
                label="Main Tag"
                style={{
                  width: "90%",
                }}
                onChange={(event) => setPrimaryTag(event.target.value)}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceArrayInput
              source="secondaryTagIds"
              reference="grammar-tags"
            >
              <SelectArrayInput
                source="tag"
                optionValue="tag"
                optionText="tag"
                label="Secondary Tags"
                style={{
                  width: "90%",
                }}
                onChange={(event) => setSecondaryTags(event.target.value)}
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.en"}
              label="en"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.vi"}
              label="vi"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.jp"}
              label="jp"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.kr"}
              label="kr"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.fr"}
              label="fr"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"localizedName.zh-cn"}
              label="zh-cn"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default GrammarCreate;
