import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { NotFound, Resource, useDataProvider, useNotify } from "react-admin";
import { Card } from "@material-ui/core";

import useResourcesActions from "../../hooks/useResourcesActions";

import VocabDataList from "../../dashboard/vocab-data/VocabDataList";
import VocabDataEdit from "../../dashboard/vocab-data/VocabDataEdit";

import VocabDataListHeader from "./VocabDataListHeader";
import CustomLoading from "../../components/CustomLoading";
import { useParams } from 'react-router-dom';

const VocabListRoute = () => {
  const { id: initialSlug = "a-bad-day-at-work" } = useParams();
  const [slug, setSlug] = useState(initialSlug);

  useEffect(() => {
    setSlug(initialSlug);
  }, [initialSlug]);

  const [choices, setChoices] = React.useState<IVocabDataItem[]>([]);
  const provider = useDataProvider();
  const notify = useNotify();

  const permissionActions = useResourcesActions({
    resource: "vocabulary-data",
  });

  React.useEffect(() => {
    provider
      .getList("vocabularies", {
        pagination: {
          perPage: 150,
          page: 1,
        },
        filter: {},
        sort: {
          field: "primaryTagId",
          order: "ASC",
        },
      })
      .then((res) => {
        if (res.total) {
          setChoices(res?.data || []);
        }
      })
      .catch((e) => {
        notify(e?.message);
      });
  }, [provider, notify]);

  if (permissionActions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissionActions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Card>
      <VocabDataListHeader
        choices={choices}
        slug={slug}
        onChange={(value: string) => {
          setSlug(value);
        }}
      />
      <Resource
        name={"data/vocabularies/" + slug}
        list={VocabDataList}
        edit={VocabDataEdit}
      />
    </Card>
  );
};

export default VocabListRoute;
