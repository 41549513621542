const firebaseConfig = {
  apiKey: "AIzaSyCUz-VQ9KQ25PIJrS4JdIoWxom1epND4qc",
  authDomain: "eflcardapp.firebaseapp.com",
  databaseURL: "https://eflcardapp.firebaseio.com",
  projectId: "eflcardapp",
  storageBucket: "eflcardapp.appspot.com",
  messagingSenderId: "340178287951",
  appId: "1:340178287951:web:e124874878a93ba515d93a",
  measurementId: "G-N21V6BBH05",
};

export default firebaseConfig;