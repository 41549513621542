import React, { useEffect } from "react";
import useResourcesActions from "../hooks/useResourcesActions";
import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";

import CustomLoading from "../components/CustomLoading";
import RoleList from "../dashboard/roles/RoleList";
import RoleEdit from "../dashboard/roles/RoleEdit";

const RoleResource = () => {
  const permissions = useResourcesActions({
    resource: "roles",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return <Resource name="roles" list={RoleList} edit={RoleEdit} />;
};

export default RoleResource;
