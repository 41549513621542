import React from "react";

import {
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from "react-admin";

import { Card } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import CustomLoading from "../../components/CustomLoading";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomCreateButton from "../../components/CustomCreateButton";

const CustomToolBar = ({
  id,
  recordId,
  resource,
  permissionActions,
}: {
  id?: string;
  recordId?: string;
  resource: string;
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions.canCreate && (
        <CustomCreateButton
          resource={resource}
          identifier={id}
          recordId={recordId}
          enableRefresh={false}
        />
      )}
    </Toolbar>
  );
};

const GrammarItemCreateRoute = () => {
  const { slug } = useParams();
  const resource = "data/grammars/" + slug;
  const { total: index = 0, isLoading } = useGetList(resource);
  const permissionActions = useResourcesActions({ resource: "grammar-data" });
  if (isLoading) {
    return <CustomLoading />;
  }

  const recordId = index?.toString();

  return (
    <Card style={{ padding: 20 }}>
      <SimpleForm
        toolbar={
          <CustomToolBar
            resource={resource}
            recordId={recordId}
            permissionActions={permissionActions}
          />
        }
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={3}>
            <TextInput
              source={"en"}
              label="en"
              style={{ marginRight: 10, width: "90%" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <TextInput
              source={"vi"}
              label="vi"
              style={{ marginRight: 10, width: "90%" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInput
              source={"sentence"}
              label="sentence"
              style={{ marginRight: 10, width: "90%" }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Card>
  );
};
export default GrammarItemCreateRoute;
