import React from "react";

import {
  SimpleForm,
  TextInput,
  ResourceProps,
  Create,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from "react-admin";
import { Grid } from "@material-ui/core";

const UserCreate = (props: ResourceProps["edit"]) => {

  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={6}>
            <TextInput
              required
              source={"email"}
              label="EMAIL"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <BooleanInput
              required
              source={"active"}
              label="ACTIVE"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              required
              source={"username"}
              label="USERNAME"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceArrayInput source="roles" reference="roles">
              <SelectArrayInput
                source="role"
                optionValue="id"
                optionText="id"
                label="ROLES"
                style={{
                  width: "90%",
                }}
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
