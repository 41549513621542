import { Grid } from "@mui/material";
import React from "react";

import {
  Edit,
  SimpleForm,
  TextInput,
  ResourceProps,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";

const PronunciationEdit = (props: ResourceProps["edit"]) => (
  <Edit {...props}>
    <SimpleForm id="name">
      <Grid container alignItems="flex-start">
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="name" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="display" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextInput source="word" />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "blueviolet",
          padding: 8,
          margin: 4,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.en" fullWidth label="ENGLISH TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "green",
          padding: 8,
          margin: 4,
          marginTop: 12,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.vi" fullWidth label="VIETNAMESE TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "green",
          padding: 8,
          margin: 4,
          marginTop: 12,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.kr" fullWidth label="KR TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
      {/* <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "green",
          padding: 8,
          margin: 4,
          marginTop: 12,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.jp" fullWidth label="JP TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "green",
          padding: 8,
          margin: 4,
          marginTop: 12,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.fr" fullWidth label="FR TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "green",
          padding: 8,
          margin: 4,
          marginTop: 12,
          borderRadius: 4,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <ArrayInput source="tips.zh-cn" fullWidth label="ZH TIPS">
            <SimpleFormIterator inline fullWidth>
              <TextInput
                source="."
                fullWidth
                format={(value) => (typeof value === "object" ? "" : value)}
                label=""
                multiline
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid> */}
    </SimpleForm>
  </Edit>
);

export default PronunciationEdit;
