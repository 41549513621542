import React from "react";

import { Create, SimpleForm, TextInput, ResourceProps } from "react-admin";

const PronunciationCreate = (props: ResourceProps["create"]) => (
  <Create {...props}>
    <SimpleForm id="name">
      <TextInput source="name" multiline />
    </SimpleForm>
  </Create>
);

export default PronunciationCreate;
