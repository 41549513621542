import React from "react";
import { CreateButton, ExportButton, TopToolbar } from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const CustomListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => (
  <TopToolbar>
    {permissionActions?.canExport && (
      <ExportButton
        style={{
          marginRight: 20,
        }}
      />
    )}
    {permissionActions.canExport && <ImportButton />}
    {permissionActions?.canCreate && <CreateButton />}
  </TopToolbar>
);

export default CustomListActions;
