import React from "react";

import {
  SimpleForm,
  TextInput,
  ResourceProps,
  Edit,
  DeleteButton,
  Toolbar,
} from "react-admin";
import { Grid } from "@material-ui/core";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomEditButton from "../../components/CustomEditButton";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && (
        <CustomEditButton resource="tags" identifier="tag"  />
      )}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const TagEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "tags",
  });

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6}>
            <TextInput
              disabled={!permissionActions?.edit?.tag}
              required
              source={"tag"}
              label="tag"
              style={{
                width: "100%",
                margin: "2.5%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          {permissionActions?.view?.en && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.en}
                required
                source={"en"}
                label="EN"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.vi && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.vi}
                required
                source={"vi"}
                label="VI"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.jp && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.jp}
                source={"jp"}
                label="JP"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.kr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.kr}
                source={"kr"}
                label="KR"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.fr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.fr}
                source={"fr"}
                label="FR"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.["zh-cn"] && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.["zh-cn"]}
                source={"zh-cn"}
                label="ZH-CN"
                style={{
                  width: "90%",
                }}
              />
            </Grid>
          )}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default TagEdit;
