import React from "react";

import { Box, CircularProgress } from "@mui/material";

const CustomLoading = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        color="secondary"
        sx={{
          marginTop: "20%",
        }}
      />
    </Box>
  );
};

export default CustomLoading;
