import React from "react";

import { ResourceProps, Edit } from "react-admin";
import GrammarRole from "./components/GrammarRole";
import VocabRole from "./components/VocabRole";
import GrammarDataRole from "./components/GrammarDataRole";
import VocabularyTagRole from "./components/VocabularyTagRole";
import VocabularyDataRole from "./components/VocabularyDataRole";
import GrammarTagRole from "./components/GrammarTagRole";
import PronunciationRole from "./components/PronunciationRole";

const RoleEdit = (props: ResourceProps["edit"]) => {
  return (
    <Edit {...props}>
      <GrammarTagRole />
      <GrammarRole />
      <GrammarDataRole />
      <VocabularyTagRole />
      <VocabRole />
      <VocabularyDataRole />
      <PronunciationRole />
    </Edit>
  );
};

export default RoleEdit;
