import * as React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";

const GrammarDataListHeader = ({
  onChange,
  slug,
  choices,
}: {
  onChange: (value: string) => void;
  slug?: string;
  choices: IVocabDataItem[];
}) => {
  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} sm={6} md={6} margin={2}>
        <Autocomplete
          options={choices.map((c) => ({
            id: c?.slug,
            label: `[${(c?.primaryTagId || "").toLowerCase()}] - ${
              c?.localizedName?.en
            }`,
          }))}
          onChange={(
            event: any,
            newValue: {
              id: string;
              label: string;
            } | null
          ) => {
            if (newValue?.id) {
              onChange?.(newValue?.id);
            }
          }}
          isOptionEqualToValue={(op) => {
            return op.id === slug;
          }}
          // disableCloseOnSelect
          renderInput={(params) => (
            <TextField {...params} label={slug} variant="standard" />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default GrammarDataListHeader;
