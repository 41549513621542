import { Grid } from "@material-ui/core";
import React from "react";
import {
  DeleteButton,
  Edit,
  ResourceProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const VocabDataEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "vocabulary-data",
  });
  return (
    <Edit {...props}>
      <SimpleForm
        {...props}
        toolbar={
          <Toolbar>
            {<SaveButton />}
            {permissionActions?.canDelete && (
              <DeleteButton
                mutationMode="pessimistic"
                style={{
                  marginLeft: 20,
                }}
              />
            )}
          </Toolbar>
        }
      >
        <Grid container spacing={3} alignItems="flex-start">
          {permissionActions?.view?.en && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.en}
                source={"en"}
                label="en"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.vi && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.vi}
                source={"vi"}
                label="vi"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.jp && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.jp}
                source={"jp"}
                label="jp"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.kr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.kr}
                source={"kr"}
                label="kr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.fr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.fr}
                source={"fr"}
                label="fr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.["zh-cn"] && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.["zh-cn"]}
                source={"zh-cn"}
                label="zh-cn"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
        </Grid>
        {permissionActions?.canEdit && (
            <TextInput source={"phonetic"} label="phonetic" />
          )}
      </SimpleForm>
    </Edit>
  );
};

export default VocabDataEdit;
