import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import {
  TextInput,
  EditButton,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  DeleteButton,
} from "react-admin";
import CustomListActions from "../../components/CustomListActions";
import { Button } from "@mui/material";
import CustomEmpty from "../../components/CustomEmpty";
// import CustomPagination from '../../components/CustomPagination';

const filters = [<TextInput label="Search Tag" source="tag" alwaysOn />];

const TagList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "tags",
  });
  return (
    <List
      {...props}
      // pagination={<CustomPagination />}
      actions={<CustomListActions permissionActions={permissionActions} />}
      filters={filters}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
      empty={<CustomEmpty />}
    >
      <Datagrid
        id="tag"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="tag" color="#2F80ED" label="ID" />
        {permissionActions?.view?.en && <TextField source={"en"} label="EN" />}
        {permissionActions?.view?.vi && <TextField source={"vi"} label="VI" />}
        {permissionActions?.view?.jp && <TextField source={"jp"} label="JP" />}
        {permissionActions?.view?.kr && <TextField source={"kr"} label="KR" />}
        {permissionActions?.view?.fr && <TextField source={"fr"} label="FR" />}
        {permissionActions?.view?.["zh-cn"] && (
          <TextField source={"zh-cn"} label="CN" />
        )}
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  );
};

export default TagList;
