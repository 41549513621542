import React from "react";

import {
  SimpleForm,
  TextInput,
  ResourceProps,
  Edit,
  DeleteButton,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
} from "react-admin";
import { Grid } from "@material-ui/core";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomEditButton from "../../components/CustomEditButton";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && <CustomEditButton resource="managers" />}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const UserEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "users",
  });

  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} sm={4} md={6}>
            <TextInput
              required
              source={"email"}
              label="EMAIL"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <BooleanInput
              required
              source={"active"}
              label="ACTIVE"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              required
              source={"username"}
              label="USERNAME"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceArrayInput source="roles" reference="roles">
              <SelectArrayInput
                source="role"
                optionValue="id"
                optionText="id"
                label="ROLES"
                style={{
                  width: "90%",
                }}
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
