import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { IconButton, Typography } from "@mui/material";
import {
  BookOutlined,
  CardGiftcardOutlined,
  LanguageOutlined,
  MicOutlined,
  TagOutlined,
  WavesOutlined,
} from "@mui/icons-material";
import { Card } from "@mui/material";
import { useRedirect } from "react-admin";

const Dashboard = () => {
  const redirect = useRedirect();

  useEffect(() => {
    // track visit
  }, []);

  return (
    <Card
      style={{
        height: "100%",
        paddingTop: "4%",
      }}
    >
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/tags")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "primary.main",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <TagOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "primary.main" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Vocabulary Tags
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/vocabularies")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "tomato",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <LanguageOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "tomato" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Vocabulary List
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/data/vocabularies")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "orange",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <CardGiftcardOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "orange" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Vocabulary Words
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/pronunciations")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "violet",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <MicOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "violet" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Pronunciations
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/grammar-tags")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "darkcyan",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <TagOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "darkcyan" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Grammar Tags
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/grammars")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "green",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <BookOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "green" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Grammars
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} sm={6}>
          <IconButton
            onClick={() => redirect("/data/grammars")}
            sx={{
              width: 200,
              height: 200,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "brown",
              flexDirection: "column",
            }}
            aria-label="save"
          >
            <WavesOutlined
              style={{
                width: 64,
                height: 64,
              }}
              sx={{ color: "brown" }}
            />
            <Typography
              variant="h6"
              component="b"
              style={{
                position: "absolute",
                bottom: 20,
              }}
            >
              Grammar Words
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Dashboard;
