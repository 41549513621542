import React from "react";

import { AppBar, Layout, UserMenu } from "react-admin";
import { MenuItem, Typography } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import CustomLogout from "./CustomLogout";

const MyUserMenu = () => (
  <UserMenu>
    <MenuItem
      onClick={() => {}}
      style={{
        borderRadius: 4,
        borderColor: "gray",
        backgroundColor: "white",
        margin: 2,
        paddingTop: 8,
        paddingBottom: 8,
        justifyContent: "space-between",
      }}
    >
      <VerifiedUserOutlined
        color="primary"
        style={{
          marginRight: 12,
        }}
      />
      <Typography color="primary.main">Profile</Typography>
    </MenuItem>
    <CustomLogout />
  </UserMenu>
);

const CustomAppBar = () => <AppBar color="primary" userMenu={<MyUserMenu />} />;

const CustomLayout = (props: any) => {
  return <Layout {...props} appBar={CustomAppBar} />;
};

export default CustomLayout;
