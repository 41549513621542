import * as React from "react";

import { useLogout } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import { Typography } from "@material-ui/core";

const CustomLogout = () => {
  const logout = useLogout();
  const handleClick = () => logout();

  return (
    <MenuItem
      onClick={handleClick}
      style={{
        borderRadius: 4,
        borderColor: "gray",
        backgroundColor: "white",
        margin: 2,
        paddingTop: 8,
        paddingBottom: 8,
        justifyContent: "flex-start",
      }}
    >
      <ExitIcon
        color="error"
        style={{
          marginRight: 12,
        }}
      />
      <Typography color="error">Logout</Typography>
    </MenuItem>
  );
};

export default CustomLogout;
