import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import {
  EditButton,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  BooleanField,
} from "react-admin";
import CustomListActions from "../../components/CustomListActions";

const UserList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "users",
  });
  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="email" color="#2F80ED" label="EMAIL" />
        <BooleanField source="active" label="ACTIVE" />
        <TextField source="username" label="USERNAME" />
        <TextField source="roles" label="ROLES" />
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  );
};

export default UserList;
