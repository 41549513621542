import React from "react";
import useResourcesActions from "../hooks/useResourcesActions";
import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";
import TagList from "../dashboard/tags";
import TagCreate from "../dashboard/tags/TagCreate";
import TagEdit from "../dashboard/tags/TagEdit";
import CustomLoading from "../components/CustomLoading";

const TagResource = () => {
  const permissions = useResourcesActions({
    resource: "tags",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource name="tags" list={TagList} create={TagCreate} edit={TagEdit} />
  );
};

export default TagResource;
