import React from "react";
import { getThumbnail } from "../utils/s3Util";
import { ImageField, useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  imgContainer: {
    height: 100,
    width: 100,
    objectFit: "contain",
  },
});

export default function S3Image({
  folder,
  label,
}: {
  folder: string;
  label: string;
}) {
  const record = useRecordContext();
  const classes = useStyles();
  
  return (
    <ImageField
      record={
        record?.image?.length
          ? {
              image: getThumbnail({
                folder,
                thumbnail: record?.image,
              }),
            }
          : undefined
      }
      source="image"
      label="image"
      className={classes.imgContainer}
      sx={{
        "& .RaImageField-image": {
          width: 100,
          height: 100,
        },
      }}
    />
  );
}
