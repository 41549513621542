import React from "react";
import {
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
} from "react-admin";
import { Grid, Typography } from "@material-ui/core";

const VocabRole = () => {
  return (
    <SimpleForm>
      <Typography variant="h6">VOCABULARY LIST</Typography>
      <Grid
        container
        alignItems="flex-start"
        style={{
          borderRadius: 12,
          margin: 4,
          padding: 2,
        }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <BooleanInput source="vocabularies.created" label="Can Create" />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <BooleanInput source="vocabularies.edited" label="Can Edit" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BooleanInput
            disabled
            source="vocabularies.exported"
            label="Can Export"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <BooleanInput
            source="vocabularies.deleted"
            label="Can Delete"
            color="error"
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        style={{
          borderRadius: 12,
          margin: 4,
          padding: 2,
        }}
      >
        <Grid item xs={12} sm={4} md={4}>
          <ReferenceArrayInput
            source="vocabularies.view"
            reference="role-fields"
            style={{
              width: "90%",
            }}
          >
            <SelectArrayInput
              color="success"
              label="VIEW FIELDS"
              source="id"
              optionText="name"
              optionValue="id"
            />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <ReferenceArrayInput
            source="vocabularies.edit"
            reference="role-fields"
            style={{
              width: "90%",
            }}
          >
            <SelectArrayInput
              color="error"
              label="EDIT FIELDS"
              source="id"
              optionText="name"
              optionValue="id"
            />
          </ReferenceArrayInput>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default VocabRole;
