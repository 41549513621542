import { usePermissions } from "react-admin";
import { isEmpty } from "lodash";

const useResourcesActions = ({
  resource,
}: {
  resource: string;
}): IPermissionActions => {
  const permissions = usePermissions();
  const resourcePermissions = permissions?.permissions?.[resource] || {};
  const permissionView: string[] = resourcePermissions?.view || [];
  const permissionEdit: string[] = resourcePermissions?.edit || [];
  const canEditAll = permissionView?.includes("*");
  const canViewAll = permissionEdit?.includes("*");
  return {
    canExport: resourcePermissions?.exported,
    canDelete: resourcePermissions?.deleted,
    canCreate: resourcePermissions?.created,
    canEdit: resourcePermissions?.edited,
    view: isEmpty(permissionView)
      ? {}
      : {
          en: permissionView?.includes("en") || canViewAll,
          vi: permissionView?.includes("vi") || canViewAll,
          kr: permissionView?.includes("kr") || canViewAll,
          jp: permissionView?.includes("jp") || canViewAll,
          fr: permissionView?.includes("fr") || canViewAll,
          "zh-cn": permissionView?.includes("zh-cn") || canViewAll,
          tag: permissionView?.includes("tag") || canViewAll,
        },
    edit: {
      en: permissionEdit?.includes("en") || canEditAll,
      vi: permissionEdit?.includes("vi") || canEditAll,
      kr: permissionEdit?.includes("kr") || canEditAll,
      jp: permissionEdit?.includes("jp") || canEditAll,
      fr: permissionEdit?.includes("fr") || canEditAll,
      "zh-cn": permissionEdit?.includes("zh-cn") || canEditAll,
      tag: permissionEdit?.includes("tag") || canEditAll,
      id: permissionEdit?.includes("id") || canEditAll,
      quantity: permissionEdit?.includes("quantity") || canEditAll,
      sentence: permissionEdit?.includes("sentence") || canEditAll,
      phonetic: permissionEdit?.includes("phonetic") || canEditAll,
    },
    loading: permissions?.isLoading,
  };
};

export default useResourcesActions;
