import React from "react";
import "./App.css";
import "./provider/awsConfig";

import { Admin, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";

import authProvider from "./provider/authProvider";
import customDataProvider from "./provider/customDataProvider";

import CustomLayout from "./components/CustomLayout";
import CustomLoading from "./components/CustomLoading";
import CustomMenu from "./components/CustomMenu";
import NotFound from "./components/NotFound";
import Dashboard from "./dashboard/index";

import PronunciationResource from "./resources/PronunciationResource";

import VocabListRoute from "./resources/vocabs/VocabListRoute";
import VocabItemEditRoute from "./resources/vocabs/VocabItemEditRoute";
import VocabItemCreateRoute from "./resources/vocabs/VocabItemCreateRoute";
import VocabTabResource from "./resources/TagResouce";
import VocabularyResource from "./resources/vocabs/VocabularyResource";

import GrammarItemCreateRoute from "./resources/grammars/GrammarItemCreateRoute";
import GrammarItemEditRoute from "./resources/grammars/GrammarItemEditRoute";
import GrammarListRoute from "./resources/grammars/GrammarListRoute";
import GrammarResource from "./resources/grammars/GrammarResource";
import GrammarTagResource from "./resources/grammars/GrammarTagResource";

import UserResource from "./resources/UserResource";
import RoleResource from "./resources/RoleResource";

function App() {
  return (
    <div className="App">
      <Admin
        loading={CustomLoading}
        disableTelemetry
        catchAll={NotFound}
        menu={CustomMenu}
        title="Admin Dashboard"
        dataProvider={customDataProvider()}
        authProvider={authProvider}
        layout={CustomLayout}
      >
        <CustomRoutes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/tags/*" element={<VocabTabResource />} />
          <Route path="/grammar-tags/*" element={<GrammarTagResource />} />
          <Route path="/pronunciations/*" element={<PronunciationResource />} />

          <Route path="/grammars/*" element={<GrammarResource />} />
          <Route path="/data/grammars/*" element={<GrammarListRoute />} />
          <Route path="data/grammars/:id" element={<GrammarListRoute />} />
          <Route
            path="data/grammars/:slug/:index/"
            element={<GrammarItemEditRoute />}
          />
          <Route
            path="data/grammars/:slug/create"
            element={<GrammarItemCreateRoute />}
          />

          <Route path="/vocabularies/*" element={<VocabularyResource />} />
          <Route path="data/vocabularies/*" element={<VocabListRoute />} />
          <Route path="data/vocabularies/:id" element={<VocabListRoute />} />
          <Route
            path="data/vocabularies/:slug/:index/"
            element={<VocabItemEditRoute />}
          />
          <Route
            path="data/vocabularies/:slug/create"
            element={<VocabItemCreateRoute />}
          />

          <Route path="/managers/*" element={<UserResource />} />
          <Route path="/roles/*" element={<RoleResource />} />
        </CustomRoutes>
      </Admin>
    </div>
  );
}

export default App;
