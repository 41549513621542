import React, { useCallback, useEffect, useState } from "react";

import {
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from "react-admin";

import { Card } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import CustomLoading from "../../components/CustomLoading";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomCreateButton from "../../components/CustomCreateButton";
import { listFiles } from "../../utils/s3Util";

const CustomToolBar = ({
  id,
  recordId,
  resource,
  permissionActions,
}: {
  id?: string;
  recordId?: string;
  resource: string;
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions.canCreate && (
        <CustomCreateButton
          resource={resource}
          identifier={id}
          recordId={recordId}
          enableRefresh={false}
        />
      )}
    </Toolbar>
  );
};

const VocabItemCreateRoute = () => {
  const { slug } = useParams();
  const resource = "data/vocabularies/" + slug;
  const { total: index = 0, isLoading } = useGetList(resource);
  const permissionActions = useResourcesActions({
    resource: "vocabulary-data",
  });

  const [images, setImages] = useState<string[]>([]);

  const fetchImages = useCallback(async () => {
    const res = await listFiles({
      folder: "vocabularies/images/" + slug,
    });
    if (res) {
      setImages(res);
    }
  }, [slug]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  if (isLoading) {
    return <CustomLoading />;
  }

  const recordId = index?.toString();

  return (
    <Card style={{ padding: 20 }}>
      <SimpleForm
        toolbar={
          <CustomToolBar
            resource={resource}
            recordId={recordId}
            permissionActions={permissionActions}
          />
        }
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.en}
              source={"en"}
              label="en"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.vi}
              source={"vi"}
              label="vi"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.jp}
              source={"jp"}
              label="jp"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.kr}
              source={"kr"}
              label="kr"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.fr}
              source={"fr"}
              label="fr"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              disabled={!permissionActions?.edit?.["zh-cn"]}
              source={"zh-cn"}
              label="zh-cn"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SelectInput
              source="image"
              choices={images.map((i) => ({
                id: i,
                name: i,
              }))}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Card>
  );
};
export default VocabItemCreateRoute;
