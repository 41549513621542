import React from "react";

import {
  EditButton,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  TextInput,
  DeleteButton,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomListActions from "../../components/CustomListActions";
import { ImportButton } from "react-admin-import-csv";
// import CustomPagination from "../../components/CustomPagination";

const filters = [<TextInput label="Search symbol" source="display" alwaysOn />];

const PronunciationList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "pronunciations",
  });

  return (
    <List
      {...props}
      empty={<ImportButton />}
      // pagination={<CustomPagination />}
      actions={<CustomListActions permissionActions={permissionActions} />}
      filters={filters}
      bulkActionButtons={permissionActions?.canDelete ? undefined : false}
      perPage={50}
    >
      <Datagrid
        id="name"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="name" color="#2F80ED" label="ID" />
        <TextField source="category" label="CATEGORY" />
        <TextField source="display" label="SYMBOL" />
        <TextField source="sound" label="SOUND" />
        <TextField source="tips.en" label="TIP EN" />
        <TextField source="tips.vi" label="TIP VI" />
        <TextField source="tips.kr" label="TIP KR" />
        <TextField source="tips.jp" label="TIP JP" />
        <TextField source="tips.fr" label="TIP FR" />
        <TextField source="tips.zh-cn" label="TIP ZH" />
        <TextField source="word" label="WORD" />
        <TextField source="youtubeId" label="YOUTUBE ID" />
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  );
};

export default PronunciationList;
