import { isUndefined } from "lodash";
import React from "react";
import {
  SaveButton,
  removeEmpty,
  useCreate,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";

const CustomCreateButton = ({
  resource,
  identifier = "",
  overrideData = {},
  enableRefresh = true,
  recordId,
}: {
  resource: string;
  identifier?: string;
  overrideData?: {
    [property: string]: any;
  };
  enableRefresh?: boolean;
  recordId?: string;
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const [create] = useCreate();

  const handleClick = (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();
    create(
      resource,
      {
        data: {
          ...removeEmpty(data),
          ...removeEmpty(overrideData),
          id: !isUndefined(id) ? id : recordId || data?.[identifier],
        },
      },
      {
        onSuccess() {
          notify(`Create ${resource} successfully!`);
          redirect("/" + resource);
          setTimeout(() => {
            enableRefresh && refresh();
          }, 50);
        },
        onError(error: any) {
          notify(error?.message, {
            type: "error",
          });
        },
      }
    );
  };

  return (
    <SaveButton
      label="SAVE AND CREATE"
      onClick={handleClick}
      type="button"
      variant="text"
    />
  );
};

export default CustomCreateButton;
