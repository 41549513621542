import React from "react";
import {
  DatagridRow,
  ImageField,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const VocabularyShow = (props: ResourceProps["show"]) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DatagridRow>
          <TextField source="slug" label="" />
        </DatagridRow>
        <TextField source={"localizedName.en"} label="" />

        <TextField source={"localizedName.vi"} label="" />

        <TextField source={"localizedName.jp"} label="" />

        <TextField source={"localizedName.kr"} label="" />

        <TextField source={"localizedName.fr"} label="" />

        <TextField source={"localizedName.zh-cn"} label="" />
      </SimpleShowLayout>
      <ImageField source={"thumbnail"} label="thumbnail" />
    </Show>
  );
};

export default VocabularyShow;
