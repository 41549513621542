import React from "react";

import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";

import CustomLoading from "../../components/CustomLoading";
import GrammarTagCreate from "../../dashboard/grammar-tags/GrammarTagCreate";
import GrammarTagEdit from "../../dashboard/grammar-tags/GrammarTagEdit";
import GrammarTagList from "../../dashboard/grammar-tags/GrammarTagList";
import useResourcesActions from "../../hooks/useResourcesActions";

const GrammarTagResource = () => {
  const permissions = useResourcesActions({
    resource: "grammar-tags",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="grammar-tags"
      list={GrammarTagList}
      edit={GrammarTagEdit}
      create={GrammarTagCreate}
    />
  );
};

export default GrammarTagResource;
