import React from "react";

import {
  BookOutlined,
  LanguageOutlined,
  MicOutlined,
  TagOutlined,
  CardGiftcardOutlined,
  DashboardCustomizeOutlined,
  WavesOutlined,
  TagTwoTone,
  Groups3TwoTone,
  AdminPanelSettings,
} from "@mui/icons-material";
import { Menu } from "react-admin";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import useResourcesActions from "../hooks/useResourcesActions";

const CustomMenu = () => {
  const names = useLocation()?.pathname;
  const permissionActions = useResourcesActions({
    resource: "users",
  });

  return (
    <Menu>
      <Menu.DashboardItem
        leftIcon={
          <DashboardCustomizeOutlined
            style={{
              color: names === "/" ? "black" : "gray",
            }}
          />
        }
        to="/"
        primaryText={
          <Typography color={names === "/" ? "black" : "gray"}>
            DashBoard
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <TagOutlined
            style={{
              color: names?.startsWith("/tags") ? "blue" : undefined,
            }}
          />
        }
        to="/tags"
        primaryText={
          <Typography color={names?.startsWith("/tags") ? "blue" : "gray"}>
            Vocabulary Tags
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <LanguageOutlined
            style={{
              color: names?.startsWith("/vocabularies") ? "red" : undefined,
            }}
          />
        }
        to="/vocabularies"
        primaryText={
          <Typography
            color={names?.startsWith("/vocabularies") ? "red" : "gray"}
          >
            Vocabulary List
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <CardGiftcardOutlined
            style={{
              color: names?.startsWith("/data/vocabularies")
                ? "orange"
                : undefined,
            }}
          />
        }
        to="/data/vocabularies"
        primaryText={
          <Typography
            color={names?.startsWith("/data/vocabularies") ? "orange" : "gray"}
          >
            Vocabulary Words
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <TagTwoTone
            style={{
              color: names?.startsWith("/grammar-tags")
                ? "darkcyan"
                : undefined,
            }}
          />
        }
        to="/grammar-tags"
        primaryText={
          <Typography
            color={names?.startsWith("/grammar-tags") ? "darkcyan" : "gray"}
          >
            Grammar Tags
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <BookOutlined
            style={{
              color: names?.startsWith("/grammars") ? "green" : undefined,
            }}
          />
        }
        to="/grammars"
        primaryText={
          <Typography color={names?.startsWith("/grammars") ? "green" : "gray"}>
            Grammar List
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <WavesOutlined
            style={{
              color: names?.startsWith("/data/grammars") ? "brown" : undefined,
            }}
          />
        }
        to="/data/grammars"
        primaryText={
          <Typography
            color={names?.startsWith("/data/grammars") ? "brown" : "gray"}
          >
            Grammar Words
          </Typography>
        }
      />
      <Menu.Item
        leftIcon={
          <MicOutlined
            style={{
              color: names?.startsWith("/pronunciations")
                ? "violet"
                : undefined,
            }}
          />
        }
        to="/pronunciations"
        primaryText={
          <Typography
            color={names?.startsWith("/pronunciations") ? "violet" : "gray"}
          >
            Pronunciations
          </Typography>
        }
      />
      {permissionActions.canCreate &&
        permissionActions.canDelete &&
        permissionActions.canCreate && (
          <Menu.Item
            leftIcon={
              <Groups3TwoTone
                style={{
                  color: names?.startsWith("/managers") ? "blue" : undefined,
                }}
              />
            }
            to="/managers"
            primaryText={
              <Typography
                color={names?.startsWith("/managers") ? "blue" : "gray"}
              >
                Users
              </Typography>
            }
          />
        )}
      {permissionActions.canCreate &&
        permissionActions.canDelete &&
        permissionActions.canCreate && (
          <Menu.Item
            leftIcon={
              <AdminPanelSettings
                style={{
                  color: names?.startsWith("/roles") ? "green" : undefined,
                }}
              />
            }
            to="/roles"
            primaryText={
              <Typography
                color={names?.startsWith("/roles") ? "green" : "gray"}
              >
                Roles
              </Typography>
            }
          />
        )}
    </Menu>
  );
};

export default CustomMenu;
