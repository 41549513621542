import React from "react";

import {
  Show,
  EditButton,
  ResourceProps,
  Datagrid,
  TextField,
} from "react-admin";

const PronunciationShow = (props: ResourceProps["show"]) => (
  <Show {...props}>
    <Datagrid id="name">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </Show>
);

export default PronunciationShow;
