import React from "react";

import {
  TextInput,
  EditButton,
  ImageField,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  useRecordContext,
  DeleteButton,
} from "react-admin";
import { getThumbnail } from "../../utils/s3Util";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomListActions from "../../components/CustomListActions";
import CustomEmpty from "../../components/CustomEmpty";
// import CustomPagination from "../../components/CustomPagination";
const isUrl = require("is-url");

const filters = [
  <TextInput label="Search English" source="localizedName.en" alwaysOn />,
  <TextInput label="Search Vietnamese" source="localizedName.vi" alwaysOn />,
];

const ImageCustomField = ({ label }: { label: string }) => {
  const record = useRecordContext();
  const isEmpty = !record?.thumbnail;
  const hasUrl = isUrl(record?.thumbnail);
  return (
    <ImageField
      title="thumbnail"
      label={label}
      record={
        hasUrl || isEmpty
          ? {
              thumbnail: record?.thumbnail,
            }
          : {
              thumbnail: getThumbnail({
                folder: "vocabularies/thumbnails",
                thumbnail: record?.thumbnail,
              }),
            }
      }
      source={"thumbnail"}
      sx={{
        "& .RaImageField-image": {
          width: 64,
          height: 64,
          margin: 0,
        },
      }}
    />
  );
};

const VocabularyList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "vocabularies",
  });

  return (
    <List
      {...props}
      // pagination={<CustomPagination />}
      actions={<CustomListActions permissionActions={permissionActions} />}
      filters={filters}
      sort={{
        order: "ASC",
        field: "slug",
      }}
      bulkActionButtons={permissionActions?.canDelete ? undefined : false}
      perPage={50}
      empty={<CustomEmpty />}
    >
      <Datagrid
        id="slug"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="slug" color="#2F80ED" label="ID" />
        <ImageCustomField label="THUMBNAIL" />
        <BooleanField source="isPopular" label="PREVIEW" />
        <BooleanField source="active" label="ACTIVE" />
        <NumberField source="size" label="QUANTITY" />
        {permissionActions?.view?.en && (
          <TextField source={"localizedName.en"} label="EN" />
        )}
        {permissionActions?.view?.vi && (
          <TextField source={"localizedName.vi"} label="VI" />
        )}
        {permissionActions?.view?.jp && (
          <TextField source={"localizedName.jp"} label="JP" />
        )}
        {permissionActions?.view?.kr && (
          <TextField source={"localizedName.kr"} label="KR" />
        )}
        {permissionActions?.view?.fr && (
          <TextField source={"localizedName.fr"} label="FR" />
        )}
        {permissionActions?.view?.["zh-cn"] && (
          <TextField source={"localizedName.zh-cn"} label="CN" />
        )}
        {permissionActions?.view?.tag && (
          <TextField source={"primaryTag.tag"} label="MAIN TAG" />
        )}
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  );
};

export default VocabularyList;
