import React from "react";

import {
  TextInput,
  EditButton,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  useResourceContext,
  CreateButton,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomListActions from "../../components/CustomListActions";
import S3Image from "../../components/S3Image";
import { Box, Card, Typography } from "@mui/material";

const GrammarDataList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: `grammar-data`,
  });
  const filters = [<TextInput label="Search word" source="en" alwaysOn />];
  const resourceName = useResourceContext();

  const Empty = () => (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No item available
      </Typography>
      <CreateButton />
    </Box>
  );

  return (
    <Card
      style={{
        padding: 20,
      }}
    >
      <List
        {...props}
        actions={<CustomListActions permissionActions={permissionActions} />}
        filters={filters}
        perPage={50}
        empty={<Empty />}
      >
        <Datagrid
          // bulkActionButtons={true}
          stickyHeader
          border={1}
          bgcolor="red"
          rowStyle={(_, index) =>
            index % 2
              ? {
                  backgroundColor: "#FAFAFA",
                  borderWidth: 0,
                }
              : {
                  backgroundColor: "#EAEAEA",
                  borderWidth: 0,
                }
          }
          sx={{
            "& .RaDatagrid-headerCell": {
              backgroundColor: "#2D9CDB",
              color: "#FFFFFF",
              borderWidth: "0.5px",
            },
            "& .RaDatagrid-rowCell": {
              borderColor: "#BDBDBD",
              borderWidth: "0.5px",
            },
          }}
        >
          <S3Image
            folder={"grammars/images/" + (resourceName|| "")?.split("/").pop() || ""}
            label="Image"
          />
          {permissionActions?.view?.en && (
            <TextField source={"en"} label="EN" />
          )}
          {permissionActions?.view?.vi && (
            <TextField source={"vi"} label="VI" />
          )}
          <TextField source={"sentence"} label="Sentence" />

          {/* {permissionActions?.view?.jp && (
            <TextField source={"jp"} label="JP" />
          )}
          {permissionActions?.view?.kr && (
            <TextField source={"kr"} label="KR" />
          )}
          {permissionActions?.view?.fr && (
            <TextField source={"fr"} label="FR" />
          )}
          {permissionActions?.view?.["zh-cn"] && (
            <TextField source={"zh-cn"} label="CN" />
          )} */}
          <EditButton />
        </Datagrid>
      </List>
    </Card>
  );
};

export default GrammarDataList;
