import React, { useEffect, useState } from "react";

import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  ResourceProps,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Grid } from "@material-ui/core";
import ImageUploader from "../../components/S3ImageUploader";
import CustomEditButton from "../../components/CustomEditButton";
import useResourcesActions from "../../hooks/useResourcesActions";

interface ITag {
  tag: string;
  en: string;
  vi: string;
}
const CustomToolBar = ({
  overrideData = {},
}: {
  overrideData?: {
    [property: string]: any;
  };
}) => {
  return (
    <Toolbar>
      <CustomEditButton
        resource="grammars"
        overrideData={overrideData}
        identifier="slug"
      />
    </Toolbar>
  );
};

const GrammarEdit = (props: ResourceProps["edit"]) => {
  const [tags, setTags] = useState<ITag[]>([]);
  const provider = useDataProvider();
  const notify = useNotify();

  const [primaryTag, setPrimaryTag] = useState(null);
  const [secondaryTags, setSecondaryTags] = useState<string[]>([]);

  const permissions = useResourcesActions({
    resource: "grammars",
  });

  useEffect(() => {
    provider
      .getList("grammar-tags", {
        pagination: {
          perPage: 100,
          page: 1,
        },
        filter: {},
        sort: {
          field: "tag",
          order: "ASC",
        },
      })
      .then((res) => {
        if (res.total) {
          setTags(res?.data || []);
        }
      })
      .catch((e) => {
        notify(e?.message);
      });
  }, [provider, notify]);

  return (
    <Edit {...props}>
      <SimpleForm
        {...props}
        toolbar={
          <CustomToolBar
            overrideData={{
              primaryTag: tags.find((t) => t.tag === primaryTag),
              secondaryTags: tags.filter((t: ITag) =>
                (secondaryTags || []).includes(t?.tag)
              ),
            }}
          />
        }
        border="GrayText"
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={4}>
            <TextInput
              disabled={!permissions?.edit?.id}
              source="slug"
              label="id"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <NumberInput
              disabled={!permissions?.edit?.quantity}
              source="size"
              label="quantity"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <BooleanInput
              source="isPopular"
              label="Preview"
              style={{
                marginRight: 10,
                width: "90%",
              }}
            />
          </Grid>
          {permissions?.canDelete && (
            <Grid item xs={6} sm={2} md={2}>
              <BooleanInput
                source="active"
                label="Active"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceInput source="primaryTagId" reference="grammar-tags">
              <SelectInput
                disabled={!permissions?.edit?.tag}
                emptyText="None"
                source="tag"
                optionValue="tag"
                optionText="tag"
                label="Main Tag"
                style={{
                  width: "90%",
                }}
                onChange={(event) => setPrimaryTag(event.target.value)}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ReferenceArrayInput
              source="secondaryTagIds"
              reference="grammar-tags"
            >
              <SelectArrayInput
                disabled={!permissions?.edit?.tag}
                source="tag"
                optionValue="tag"
                optionText="tag"
                label="Secondary Tags"
                style={{
                  width: "90%",
                }}
                onChange={(event) => setSecondaryTags(event.target.value)}
              />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          {permissions?.view?.en && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.en}
                source={"localizedName.en"}
                label="en"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissions?.view?.vi && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.vi}
                source={"localizedName.vi"}
                label="vi"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissions?.view?.jp && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.jp}
                source={"localizedName.jp"}
                label="jp"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissions?.view?.kr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.kr}
                source={"localizedName.kr"}
                label="kr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissions?.view?.fr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.fr}
                source={"localizedName.fr"}
                label="fr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissions?.view?.["zh-cn"] && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissions?.edit?.["zh-cn"]}
                source={"localizedName.zh-cn"}
                label="zh-cn"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
        </Grid>
        <ImageUploader folder="grammars/thumbnails" />
      </SimpleForm>
    </Edit>
  );
};

export default GrammarEdit;
