import React from "react";
import useResourcesActions from "../hooks/useResourcesActions";
import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";
import PronunciationList from "../dashboard/pronunciations";
import PronunciationShow from "../dashboard/pronunciations/PronunciationShow";
import PronunciationCreate from "../dashboard/pronunciations/PronunciationCreate";
import PronunciationEdit from "../dashboard/pronunciations/PronunciationEdit";
import CustomLoading from "../components/CustomLoading";

const PronunciationResource = () => {
  const permissions = useResourcesActions({
    resource: "pronunciations",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="pronunciations"
      list={PronunciationList}
      show={PronunciationShow}
      create={PronunciationCreate}
      edit={PronunciationEdit}
    />
  );
};

export default PronunciationResource;
