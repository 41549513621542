import React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
  ResourceProps,
  Toolbar,
} from "react-admin";
import { Grid } from "@material-ui/core";
import CustomCreateButton from "../../components/CustomCreateButton";

const CustomToolBar = () => {
  return (
    <Toolbar>
      <CustomCreateButton resource="grammar-tags" identifier="tag" />
    </Toolbar>
  );
};

const GrammarTagCreate = (props: ResourceProps["create"]) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6}>
            <TextInput
              required
              source={"tag"}
              label="tag"
              style={{
                width: "100%",
                margin: "2.5%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              required
              source={"en"}
              label="EN"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              required
              source={"vi"}
              label="VI"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"jp"}
              label="JP"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"kr"}
              label="KR"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"fr"}
              label="FR"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextInput
              source={"zh-cn"}
              label="ZH-CN"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default GrammarTagCreate;
