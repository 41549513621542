import React, { useCallback, useEffect, useState } from "react";
import {
  DeleteButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
} from "react-admin";
import { Card } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import useResourcesActions from "../../hooks/useResourcesActions";
import CustomLoading from "../../components/CustomLoading";
import { listFiles } from "../../utils/s3Util";
import CustomCreateButton from "../../components/CustomCreateButton";
import CustomEditButton from "../../components/CustomEditButton";

const CustomToolBar = ({
  id,
  recordId,
  resource,
  permissionActions,
}: {
  id?: string;
  recordId?: string;
  resource: string;
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions.canEdit && (
        <CustomEditButton resource={resource} identifier={id} />
      )}
      {permissionActions?.canDelete && (
        <DeleteButton
          resource={resource}
          mutationMode="pessimistic"
          style={{ marginLeft: 36 }}
        />
      )}
    </Toolbar>
  );
};
const VocabItemEditRoute = () => {
  const { slug, index } = useParams();
  const resource = "data/vocabularies/" + slug;
  const { data: record, isLoading } = useGetOne("data/vocabularies/" + slug, {
    id: index?.toString(),
  });
  const [images, setImages] = useState<string[]>([]);

  const permissionActions = useResourcesActions({
    resource: "vocabulary-data",
  });

  const fetchImages = useCallback(async () => {
    const res = await listFiles({
      folder: "vocabularies/images/" + slug,
    });
    if (res) {
      setImages(res);
    }
  }, [slug]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  if (isLoading) {
    return <CustomLoading />;
  }

  return (
    <Card style={{ padding: 20 }}>
      <SimpleForm
        record={record}
        toolbar={
          <CustomToolBar
            resource={resource}
            id={index?.toString()}
            permissionActions={permissionActions}
          />
        }
      >
        <Grid container spacing={3} alignItems="flex-start">
          {permissionActions?.view?.en && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.en}
                source={"en"}
                label="en"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.vi && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.vi}
                source={"vi"}
                label="vi"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.jp && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.jp}
                source={"jp"}
                label="jp"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.kr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.kr}
                source={"kr"}
                label="kr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.fr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.fr}
                source={"fr"}
                label="fr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.["zh-cn"] && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.["zh-cn"]}
                source={"zh-cn"}
                label="zh-cn"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={2}>
            {permissionActions?.canEdit && (
              <TextInput source={"phonetic"} label="phonetic" />
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <SelectInput
              source="image"
              choices={images.map((i) => ({
                id: i,
                name: i,
              }))}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Card>
  );
};

export default VocabItemEditRoute;
