import AWS from "aws-sdk";

const s3 = new AWS.S3();

export const getThumbnail = ({
  folder = "",
  thumbnail,
}: {
  folder: string;
  thumbnail: string;
}) => {
  let params: any = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: folder + "/" + thumbnail,
  };
  const url: string = s3.getSignedUrl("getObject", params);
  return url;
};

export const listFiles = async ({ folder }: { folder: string }) => {
  const params: AWS.S3.ListObjectsRequest = {
    Bucket: process.env.REACT_APP_AWS_BUCKET || "",
    Delimiter: "/",
    Prefix: folder + "/",
  };

  const data = await s3.listObjects(params).promise();
  let res: string[] = [];
  for (let index = 0; index < (data?.Contents || [])?.length || 0; index++) {
    const imgPath = data?.Contents?.[index]["Key"] || "";
    if (imgPath) {
      res.push(imgPath?.split("/").pop() || "");
    }
  }
  return res;
};
