import { uniqBy } from "lodash";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  LegacyDataProvider,
} from "react-admin";
import { FirebaseDataProvider } from "react-admin-firebase";
import firebaseConfig from "./firebaseConfig";
import firebaseOptions from "./firebaseOptions";
import authProvider from "./authProvider";

const IDS: {
  [key: string]: string;
} = {
  vocabularies: "slug",
  pronunciations: "name",
  "data/vocabularies": "slug",
  "data/grammars": "slug",
  tags: "tag",
};

const customDataProvider = (): DataProvider | LegacyDataProvider => {
  const defaultProvider = FirebaseDataProvider(firebaseConfig, firebaseOptions);
  return {
    getList: async (resource: string, params: GetListParams) => {
      const response: GetListResult<any> | void = await FirebaseDataProvider(
        firebaseConfig,
        firebaseOptions
      )
        .getList(resource, params)
        .then((res: any) => res)
        .catch((_err: any) => {
          return Promise.resolve({
            data: [],
            total: 0,
            pageInfo: {
              hasNextPage: false,
              hasPreviousPage: false,
            },
          });
        });

      const id = IDS[resource] || "id";
      const data = uniqBy(response?.data, id) || [];
      const duplicateLength = (response?.data?.length || 0) - data?.length;
      const total = (response?.total || 0) - duplicateLength;

      const page = params?.pagination?.page;
      const perPage = params.pagination.perPage;
      const result = {
        ...response,
        data: data || [],
        total: total,
        pageInfo: {
          hasNextPage: page < total / perPage,
          hasPreviousPage: page > 1,
        },
      };
      return Promise.resolve(result);
    },
    getOne: defaultProvider.getOne,
    getMany: defaultProvider.getMany,
    getManyReference: defaultProvider.getManyReference,
    update: defaultProvider.update,
    updateMany: defaultProvider.updateMany,
    create: defaultProvider.create,
    delete: defaultProvider.delete,
    deleteMany: defaultProvider.deleteMany,
  };
};

export default customDataProvider;
