import React, { useRef } from "react";
import AWS from "aws-sdk";
import { getThumbnail } from "../utils/s3Util";
import isUrl from "is-url";
import {
  ImageField,
  removeEmpty,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  imgContainer: {
    height: 100,
    width: 100,
    objectFit: "contain",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -50,
  },
});

export default function ImageUploader({ folder }: { folder: string }) {
  const resourceName = useResourceContext();
  const record = useRecordContext();
  const classes = useStyles();
  const s3 = new AWS.S3();
  const [file, setFile] = React.useState<any>(null);
  const [preview, setPreview] = React.useState<any>();
  const refFile = useRef<any>(null);
  const [update] = useUpdate();

  // const fetchImages = useCallback(async () => {
  //   await listFiles({
  //     folder,
  //   });
  // }, [folder]);

  // useEffect(() => {
  //   fetchImages();
  // }, [fetchImages]);

  const renderCurrentImage = () => {
    const isEmpty = !record?.thumbnail;
    const hasUrl = isUrl(record?.thumbnail);
    if (hasUrl || isEmpty) {
      return (
        <ImageField
          source="thumbnail"
          label="thumbnail"
          className={classes.imgContainer}
        />
      );
    }

    return (
      <img
        src={getThumbnail({
          folder,
          thumbnail: record?.thumbnail,
        })}
        alt="thumbnail"
        // className="RaImageField-image"
        width="100"
        height="100"
        style={{}}
      />
    );
  };

  const handleFileSelect = (e: any) => {
    const selectFile = e.target.files[0];
    if (selectFile) {
      setFile(selectFile);
      const objectUrl = URL.createObjectURL(selectFile);
      setPreview(objectUrl);
    }
  };

  const uploadToS3 = async () => {
    if (!file) {
      return;
    }
    const params: AWS.S3.PutObjectRequest = {
      Bucket: process.env.REACT_APP_AWS_BUCKET || "",
      Key: `${folder}/${record?.slug}.png`,
      Body: file,
    };
    await s3.upload(params).promise();
    update(resourceName, {
      id: record?.id,
      data: {
        thumbnail: `${record?.slug}.png`,
      },
      previousData: removeEmpty(record),
    });
  };

  const removeFile = () => {
    refFile.current.value = null;
    setFile(null);
    setPreview(null);
  };

  return (
    <div
      style={{
        flexDirection: "column",
        marginTop: "10px",
        alignItems: "flex-start",
        display: "flex",
      }}
    >
      <p style={{ textAlign: "left" }}>Current Thumbnail</p>
      {renderCurrentImage()}
      <p style={{ textAlign: "left" }}>Change Thumbnail</p>
      <input ref={refFile} type="file" onChange={handleFileSelect} />
      {!!file && (
        <div style={{ marginTop: "10px" }}>
          <img
            src={preview}
            alt="upload file"
            width={100}
            height={100}
            style={{
              borderColor: "blueviolet",
              borderWidth: 1,
            }}
          />
        </div>
      )}
      {file && (
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <button onClick={uploadToS3}>Upload</button>
          <button
            style={{
              marginLeft: 20,
              color: "red",
            }}
            onClick={removeFile}
          >
            Remove
          </button>
        </div>
      )}
      <br />
    </div>
  );
}
