import React from "react";

import {
  TextInput,
  EditButton,
  ImageField,
  ResourceProps,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  useRecordContext,
  DeleteButton,
} from "react-admin";
import { getThumbnail } from "../../utils/s3Util";
import useResourcesActions from "../../hooks/useResourcesActions";
import CustomListActions from '../../components/CustomListActions';
const isUrl = require("is-url");

const filters = [
  <TextInput label="Search English" source="localizedName.en" alwaysOn />,
  <TextInput label="Search Vietnamese" source="localizedName.vi" alwaysOn />,
];

const ImageCustomField = () => {
  const record = useRecordContext();
  const isEmpty = !record?.thumbnail;
  const hasUrl = isUrl(record?.thumbnail);
  return (
    <ImageField
      label="THUMBNAIL"
      record={
        hasUrl || isEmpty
          ? {
              thumbnail: record?.thumbnail,
            }
          : {
              thumbnail: getThumbnail({
                folder: "grammars/thumbnails",
                thumbnail: record?.thumbnail,
              }),
            }
      }
      source={"thumbnail"}
      sx={{
        "& .RaImageField-image": {
          width: 64,
          height: 64,
          margin: 0,
        },
      }}
    />
  );
};

const GrammarList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "grammars",
  });
  return (
    <List
      {...props}
      // pagination={<CustomPagination />}
      actions={<CustomListActions permissionActions={permissionActions} />}
      filters={filters}
      bulkActionButtons={permissionActions?.canDelete ? undefined : false}
      perPage={50}
    >
      <Datagrid
        
        id="slug"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="slug" color="#2F80ED" label="ID" />
        <ImageCustomField />
        <BooleanField source="isPopular" label="PREVIEW" />
        <BooleanField source="active" label="ACTIVE" />
        <NumberField source="size" label="QUANTITY" />
        <TextField source={"localizedName.en"} label="EN" />
        <TextField source={"localizedName.vi"} label="VI" />
        <TextField source={"localizedName.jp"} label="JP" />
        <TextField source={"localizedName.kr"} label="KR" />
        <TextField source={"localizedName.fr"} label="FR" />
        <TextField source={"localizedName.zh-cn"} label="CN" />
        <TextField source={"primaryTag.tag"} label="MAIN TAG" />
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && <DeleteButton mutationMode="pessimistic"/>}
      </Datagrid>
    </List>
  );
};

export default GrammarList;
