import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";
import { isEmpty } from "lodash";
import { NotFound, Resource } from "react-admin";
import VocabularyList from "../../dashboard/vocabularies";
import VocabularyShow from "../../dashboard/vocabularies/VocabularyShow";
import VocabularyCreate from "../../dashboard/vocabularies/VocabularyCreate";
import VocabularyEdit from "../../dashboard/vocabularies/VocabularyEdit";
import CustomLoading from "../../components/CustomLoading";

const VocabularyResource = () => {
  const permissions = useResourcesActions({
    resource: "vocabularies",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (isEmpty(permissions?.view)) {
    return <NotFound title="No permission or Not found page" />;
  }

  return (
    <Resource
      name="vocabularies"
      list={VocabularyList}
      show={VocabularyShow}
      create={VocabularyCreate}
      edit={VocabularyEdit}
    />
  );
};

export default VocabularyResource;
