import React from "react";
import {
  SaveButton,
  removeEmpty,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import { useFormContext } from "react-hook-form";

const CustomEditButton = ({
  resource,
  overrideData = {},
  identifier = "",
  keepEmpty = false,
}: {
  resource: string;
  overrideData?: {
    [property: string]: any;
  };
  identifier?: string;
  keepEmpty?: boolean;
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const [update] = useUpdate();

  const handleClick = (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();
    update(
      resource,
      {
        id: id || data?.[identifier],
        data: {
          ...removeEmpty(data),
          ...removeEmpty(overrideData),
        },
      },
      {
        onSuccess() {
          notify(`update ${resource} successfully!`);
          redirect("/" + resource);
          setTimeout(() => {
            refresh();
          }, 50);
        },
        onError(error: any) {
          notify(error?.message, {
            type: "error",
          });
        },
      }
    );
  };

  return (
    <SaveButton
      label="SAVE AND UPDATE"
      onClick={handleClick}
      type="button"
      variant="text"
    />
  );
};

export default CustomEditButton;
