import { Grid } from "@material-ui/core";
import React from "react";
import {
  DeleteButton,
  Edit,
  ResourceProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";
import { listFiles } from "../../utils/s3Util";
import { useParams } from "react-router-dom";
import CustomLoading from "../../components/CustomLoading";

const GrammarDataEdit = (props: ResourceProps["edit"]) => {
  const { slug, index } = useParams();

  const { data: record, isLoading } = useGetOne("data/grammars/" + slug, {
    id: index?.toString(),
  });

  const permissionActions = useResourcesActions({
    resource: "grammar-data",
  });

  const [images, setImages] = React.useState<string[]>([]);

  const fetchImages = React.useCallback(async () => {
    const res = await listFiles({
      folder: "grammars/images/" + slug,
    });
    if (res) {
      setImages(res);
    }
  }, [slug]);

  React.useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  if (isLoading) {
    return <CustomLoading />;
  }

  return (
    <Edit {...props}>
      <SimpleForm
        {...props}
        record={record}
        toolbar={
          <Toolbar>
            {<SaveButton />}
            {permissionActions?.canDelete && (
              <DeleteButton
                mutationMode="pessimistic"
                style={{
                  marginLeft: 20,
                }}
              />
            )}
          </Toolbar>
        }
      >
        <Grid container spacing={3} alignItems="flex-start">
          {permissionActions?.view?.en && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.en}
                source={"en"}
                label="en"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.vi && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.vi}
                source={"vi"}
                label="vi"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {/* {permissionActions?.view?.jp && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.jp}
                source={"jp"}
                label="jp"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.kr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.kr}
                source={"kr"}
                label="kr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.fr && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.fr}
                source={"fr"}
                label="fr"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )}
          {permissionActions?.view?.["zh-cn"] && (
            <Grid item xs={12} sm={4} md={2}>
              <TextInput
                disabled={!permissionActions?.edit?.["zh-cn"]}
                source={"zh-cn"}
                label="zh-cn"
                style={{
                  marginRight: 10,
                  width: "90%",
                }}
              />
            </Grid>
          )} */}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default GrammarDataEdit;
