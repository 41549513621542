import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import { ResourceProps, List, Datagrid, TextField, EditButton, DeleteButton } from "react-admin";
import CustomListActions from "../../components/CustomListActions";

const RoleList = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "roles",
  });
  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="id" color="#2F80ED" label="ID" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default RoleList;
